import styled from 'styled-components';

const StyledCheckbox = styled.input`
    margin: 18px 0px 18px 9px;
`
const ContainerStyledCheckbox = styled.div``;

const StyledCheckboxTitle = styled.span`
    font-size: 1em;
    color: #555;
    margin-left: 3px;
    position: relative;
    top: -1px;
`;

export { StyledCheckbox, StyledCheckboxTitle, ContainerStyledCheckbox };