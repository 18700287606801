const keyCodeList = {
    ENTER: 13
}

const handleKeyPress = (event: any, keynumber: number, cb: any) => {
    if(event.charCode === keynumber){
        cb();
    }
}

export { handleKeyPress, keyCodeList }