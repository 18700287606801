import styled from 'styled-components';

const Container = styled.div`
    padding: 12px;
`

const StaticIcon = styled.img`
    flex: 1;
    max-width: 32px;
    max-height: 32px;
`;

const DivError = styled.div`
    padding: 10px;
    color:  #fff;
    background: rgba(255,37,37,0.85);
    font-weight: bold;
    margin-bottom: 15px;
`;

const AttachmentsDiv = styled.div`
    flex: 1;
`;

const AttachmentItem = styled.div`
    padding-top: 25px;
    padding-bottom: 25px;
    border: 0px solid #000;
    padding-left: 10px;
    align-items: center;
    display: flex;
`;

const AttachmentText = styled.div`
    position: relative;
    top: 3px;
`;

export { Container, StaticIcon, DivError, AttachmentsDiv, AttachmentItem, AttachmentText };
