import React, {useState, useEffect} from 'react';
import { ListHeaderContainer, ListHeaderTitle, ListHeaderToolImg  } from './style';
import { NavigateLib } from '@src/lib/navigate';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import ModalDialog from '@src/components/ModalDialog';
import { LocalStorage } from "@infra/localStorage";
import api from "@infra/api";
import { Input } from '@src/components/Input';
import backIcon from '@src/assets/icons/back.png';
import MoreMenu from '@src/components/MoreMenu';
import moveIcon from '@src/assets/icons/move.png';
import cancelIcon from '@src/assets/icons/cancel.png';
import sourceTracking from "@src/lib/sourceTracking";

function ListHeader(props: any){
    const navigate = useNavigate();
    const [modalSettings, setModalSettings] = useState({});
    const [registerIdToMove, setRegisterIdToMove] = useState(0);
    const [doMoveCssRightValue, setDoMoveCssRightValue] = useState('-100px');
    const [cancelCssRightValue, setCancelCssRightValue] = useState('');
    const locator = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const markToBeMoved = async () => {
        sourceTracking("markToBeMoved", "ListHeader");
        await LocalStorage.set('toBeMoved', props.registerId);
        setRegisterIdToMove(parseInt(props.registerId));
    }

    const checkMoveFlag = async () => {
        sourceTracking("checkMoveFlag", "ListHeader");
        let toBeMovedTmp = await LocalStorage.get('toBeMoved');
        let toBeMoved = parseInt(toBeMovedTmp+"");
        if (toBeMoved){
            setRegisterIdToMove(toBeMoved);
        }
    }

    const removeMoveStatus = async() => {
        sourceTracking("removeMoveStatus", "ListHeader");
        LocalStorage.remove('toBeMoved');
        setRegisterIdToMove(0);
    }

    const doMove = async() => {
        sourceTracking("doMove", "ListHeader");
        props.setLoading(true);
        await api.put("/register/"+registerIdToMove, {
            "parent_id": props.registerId ? props.registerId : 0
        });
        removeMoveStatus();
        window.location.reload();
    }
    
    const backHistory = () => {
        sourceTracking("backHistory", "ListHeader");
        props.setLoading(true);
        NavigateLib.navigate(navigate, props.backTo);
    }

    const edit = () => {
        sourceTracking("edit", "ListHeader");
        props.setLoading(true);
        NavigateLib.navigate(navigate, '/registerEdit/'+props.registerId+'?group=true')
    }

    const goHome = () => {
        sourceTracking("goHome", "ListHeader");
        props.setLoading(true);
        NavigateLib.navigate(navigate, '/')
    }

    const about = () => {
        sourceTracking("about", "ListHeader");
        setModalSettings({
            open: true,
            title: 'MocPassword',
            message: 'App guardião de senhas',
            buttons: [
                {
                    label: 'Ok',
                    onClick: async () => {
                        setModalSettings({open: false});
                    }
                }
            ]
        })
    }

    const stats = () => {
        sourceTracking("stats", "ListHeader");
        props.setLoading(true);
        NavigateLib.navigate(navigate, '/stats')
    }

    const remove = () => {
        sourceTracking("remove", "ListHeader");
        setModalSettings({
            open: true,
            title: 'Apagar permanentemente',
            message: 'Deseja realmente apagar este grupo inteiro ?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        try{
                            props.setLoading(true);

                            await api.delete("/registerGroup/"+props.registerId);

                            setTimeout(async () => {
                                let tempParentId = parseInt(props.parentId);
                                if (isNaN(tempParentId) || tempParentId <= 0){
                                    await NavigateLib.navigate(navigate, '/');
                                }
                                else{
                                    let result = await api.get("/register/"+tempParentId);
                                    if (result.childs.length === 0){
                                        if (result.parent_id === null){
                                            await NavigateLib.navigate(navigate, '/');        
                                        } else {
                                            await NavigateLib.navigate(navigate, '/registerList/'+result.parent_id);
                                        }
                                    } else{
                                        await NavigateLib.navigate(navigate, '/registerList/'+tempParentId);
                                    }
                                }
                            }, 1000);

                        } catch (e: any){
                            if (e.response.status === 403){
                                LocalStorage.remove("authToken");
                                NavigateLib.navigate(navigate, "/login");
                            } else {
                                alert("Erro ao apagar entrada");
                            }
                        }

                        setModalSettings({open: false});
                    }
                },
                {
                    label: 'Não',
                    onClick: async () => {
                        setModalSettings({open: false});
                    }
                }
            ]
        });
    }
    
    const buscarAction = async () => {
        props.setLoading(true);

        try{
            NavigateLib.removeQueryParams(searchParams, setSearchParams, "buscar");

            let searchFromHtml = (document.getElementById('findInput') as HTMLInputElement).value;
            let result = await api.get("/register?query="+searchFromHtml);
            props.setRegistersFind(result);
        } catch (e: any){
            switch(e.response.status){
                case 403:
                    LocalStorage.remove("authToken");
                    NavigateLib.navigate(navigate, "/login");
                    break;

                case 404:
                    props.setRegistersFind([]);
                    //alert("Nenhum resultado encontrado");
                    break;

                default:
                    alert("Erro ao buscar entradas");
                    break;
            }
        }

        props.setLoading(false);
        setModalSettings({open: false});
    }

    const openFind = () => {
        sourceTracking("openFind", "ListHeader");
        setModalSettings({
            open: true,
            title: 'Buscar',
            message: <>
                <div>
                    <Input
                            id="findInput"
                            autoFocus
                            placeholder=""
                            label="Buscar entrada"
                            autocomplete="off"
                            style={{width: "318px"}}
                            locked={false}
                            onKeyPress={onKeyPressfindInputHandler}
                    />
                </div>
                <br/><br/><br/>
            </>,
            buttons: [
                {
                    label: 'Buscar',
                    onClick: async () => {
                        buscarAction();
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: async () => {
                        setModalSettings({open: false});
                    },
                    style: {
                        "backgroundColor": "#777"
                    }
                }
            ]
        });
    }

    const onKeyPressfindInputHandler = (e: any) => {
        if (e.charCode === 13){
            buscarAction();
        }
    }

    useEffect(() => {
        sourceTracking("useEffect", "ListHeader");
        checkMoveFlag();

        if (props.displayBackButton){
            setDoMoveCssRightValue('0');
        }

        let buscarParam = NavigateLib.query(locator, 'buscar');
        
        console.log(buscarParam)
        if (buscarParam){
            openFind();
        }
    }, []);

    useEffect(() => {
        sourceTracking("useEffect (com registerIdToMove e props.registerId)", "ListHeader");
        if (registerIdToMove !== parseInt(props.registerId)){
            setCancelCssRightValue('90px');
        } else {
            setCancelCssRightValue('160px');
        }
    }, [registerIdToMove, props.registerId])

    return (
        <>
            {registerIdToMove !== 0 ?
            <>
                    <ListHeaderContainer>
                        {props.displayBackButton ? <div onClick={backHistory}><ListHeaderToolImg src={backIcon} /></div>: <></>}

                        {registerIdToMove !== parseInt(props.registerId) ? <>
                            <div onClick={doMove} style={{right: doMoveCssRightValue, position: 'relative'}}>
                                <ListHeaderToolImg style={{right: '15px', position: 'relative', top: '3px'}} src={moveIcon} />
                                <span style={{position: 'relative', top: '-2px'}}>
                                    Mover
                                </span>
                            </div>
                        </>:<></>}
                        <div onClick={removeMoveStatus} style={{float: 'left', marginRight: cancelCssRightValue}}>
                            <ListHeaderToolImg style={{paddingRight: '0px', right: '15px', position: 'relative', top: '3px'}} src={cancelIcon} />
                            <span style={{position: 'relative', top: '-2px'}}>
                                Cancelar
                            </span>
                        </div>
                    </ListHeaderContainer>
            </>: <>
                <ListHeaderContainer>
                    {props.displayBackButton ? <div onClick={backHistory}><ListHeaderToolImg src={backIcon} /></div>: <></>}
                    <ListHeaderTitle style={{position: 'relative'}}>
                        {props.title}
                    </ListHeaderTitle>
                    <MoreMenu id="topRightMenu" color="light">
                        <nav>
                            <ul className="nav">
                                <li>
                                    <span onClick={goHome}>Home</span>
                                </li>
                                <li>
                                    <span onClick={openFind}>Buscar</span>
                                </li>

                                <li>
                                    <span onClick={stats}>Stats</span>
                                </li>
                                {props.registerId ? <>
                                    <li>
                                        <span onClick={edit}>Editar</span>
                                    </li>
                                    <li>
                                        <span onClick={remove}>Excluir</span>
                                    </li>
                                    
                                    {registerIdToMove !== parseInt(props.registerId) ? <>
                                        <li>
                                            <span onClick={markToBeMoved} >Mover</span>
                                        </li>
                                    </> : <></>}
                                </>:<>
                                    <li>
                                        <span onClick={about}>Sobre</span>
                                    </li>
                                </>}
                            </ul>
                            </nav>
                    </MoreMenu>
                    
                </ListHeaderContainer>
                <ModalDialog settings={modalSettings} />
            </>}
        </>
    )
};

export { ListHeader };