import { LegendContainer, SubLegend, UlLegend, LiLegend, LegendValue } from './style';

function Legend (props: any){
    const searchByIds = (item: any) => {
        props.setIdsSearch(item.ids)
    }

    return (<LegendContainer>
                <UlLegend >
                    {
                        props.data.map(
                            (item: any, index: number) => (
                                <LiLegend key={index} onClick={() => {searchByIds(item)}} color={props.colors[index]}>
                                    <LegendValue>{item.name}<br/></LegendValue>
                                    <SubLegend>{item.value}x</SubLegend>
                                </LiLegend>
                            )
                        )
                    }
                </UlLegend>
    </LegendContainer>)
}

export { Legend }