import { LoadingContainer, Gif } from './style';
import loadingIcon from '@src/assets/icons/loading.gif';

function Loading(props: any){
    // TODO: Default icon sempre aparecendo. Tornar dinâmico
    // type: "root" | "child"

    return (
        <LoadingContainer>
            <Gif src={loadingIcon} />
        </LoadingContainer>
    )
}

export { Loading }