import React, {useState, useEffect, useContext, useCallback} from 'react';
// import MenuIcon from 'images/menu.svg'
import "./style.css";
import menuMoreLight from '@src/assets/icons/menuMoreLight.png';
import menuMoreDark from '@src/assets/icons/menuMoreDark.png';
import { AppContext } from '@src/contexts/appContext';

function MoreMenu(props: any) {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState('close');

    const [clickCounter, setClickCounter] = useState(0);
    const { actionBroadcast, setActionBroadcast, outsideMenuClickDetected, setOutsideMenuClickDetected } = useContext(AppContext);

    const close = useCallback(() => {
        setOpen(false);
        setStatus("close");
        setOutsideMenuClickDetected({status: false, origin: 'moreMenu'});
    }, [setOutsideMenuClickDetected]);

    useEffect(() => {
        if (
            actionBroadcast.action === "closeMoreMenu" &&
            actionBroadcast.sender !== props.id
        ){
            close()
        }
    }, [actionBroadcast, close, props.id]);

    useEffect(() => {
        if (
            outsideMenuClickDetected.status === true &&
            outsideMenuClickDetected.origin === 'appContext'
        ){
            close()
        }
    }, [outsideMenuClickDetected])

    const handleToggle = () => {
        setClickCounter(clickCounter+1);
        setOpen(!open)

        if (status === "close"){
            setActionBroadcast({
                action: "closeMoreMenu",
                sender: props.id
            });
            setStatus("open");
        } else {
            setOutsideMenuClickDetected({status: false, origin: 'moreMenu'});
            setStatus("close");
        }
    }    

    const handleClickOutside = (ev: any) => {
        close()
    }

    return (
        <div id={props.id} className='menu-wrapper' onClick={handleToggle}>
            <img src={props.color === "light" ? menuMoreLight : menuMoreDark} alt='Menu Icon' className='menu-icon' />
            <div className={`menu menu-${status}`} onClick={handleClickOutside}>
                {props.children}
            </div>
        </div>
    )
}

export default MoreMenu;
