import styled from 'styled-components';

const StyleItemList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const TitleItemList = styled.div`
    flex: 1;
`;

const TypeItemList = styled.div`
    margin-right: 3px;
`;

const IconContainer = styled.div`
    margin-right: 10px;
`;

const DataContainer = styled.div`
    width: 80%;
`;

const SubDataContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2px;
    color: #777;
    font-size: 0.8em;
`;

const IconItemList = styled.img`
    flex: 1;
    padding: 12px;
    max-width: 32px;
`;

const NextIcon = styled.img`
    flex: 1;
    padding: 12px;
    max-width: 24px;
    max-height: 24px;
`;

export { StyleItemList, TitleItemList, TypeItemList, IconItemList, NextIcon, IconContainer, DataContainer, SubDataContainer };