import "./style.css";
import React, {useState, useEffect} from 'react';

function TextArea(props: any) {
    const { locked, id } = props;

    const [active, setActive] = useState(false);
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const [label, setLabel] = useState("");
    const [placeholder, setPlaceholder] = useState("");

    const fieldClassName = `textarea ${(value) && "active"} ${locked && "locked"}`;

    useEffect(() => {
        if (props.value){
            setValue(props.value);
        }
        if (props.placeholder){
            setPlaceholder(props.placeholder);
        }
        if (props.label){
            setLabel(props.label);
        }
    }, []);

    const changeValue = (event: any) => {
        const valueFromEvent = event.target.value;
        setValue(valueFromEvent);
        setError("");
        if (props.onChange){
            props.onChange(valueFromEvent);
        }
    }

    const handleKeyPress = (event: any) => {
        if (event.which === 13) {
          
        }
    }

    const handleStatusToggle = (status: boolean) => {
        setActive(status);
    }

    return (
        <div className={fieldClassName}>
            {active &&
            value
            }
                <div>
                    <label className={error && "error"}>
                        {error || label}
                    </label>
                </div>
                <textarea
                    id={id}
                    value={value}
                    placeholder={placeholder}
                    onChange={changeValue}
                    onKeyPress={handleKeyPress}
                    onFocus={() => !locked && handleStatusToggle(false)}
                    onBlur={() => !locked && handleStatusToggle(false)}
                />
        </div>
    )
}

export { TextArea }