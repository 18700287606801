import React, {useState, useEffect} from 'react';
import { Container, TitleItemList, TypeItemList, TextAreaItemList, IconItemList, ActionsIcon, IconContainer, DataContainer, SubDataContainer, TitleItemDetails, SubDataDetailsContainer, DivError, AttachmentsDiv, AttachmentItem, AttachmentText } from './style';
import menuMoreIcon from '@src/assets/icons/menuMoreDark.png';
import { useNavigate, useParams } from "react-router-dom";
import { NavigateLib } from '@src/lib/navigate';
import { unsecuredCopyToClipboard } from '@src/lib/unsecuredCopyToClipboard';
import { ItemHeader } from '@src/components/Headers/ItemHeader';
import defaultFolderIcon from '@src/assets/icons/defaultFolder.png';
import defaultItemIcon from '@src/assets/icons/defaultItem.png';
import attachmentIcon from '@src/assets/icons/attachment.png';
import emptyIcon from '@src/assets/icons/empty.png';
import downloadIcon from '@src/assets/icons/download.png';
import createdAtIcon from '@src/assets/icons/created_at.png';
import updatedAtIcon from '@src/assets/icons/updated_at.png';
import keyIcon from '@src/assets/icons/key.png';
import userIcon from '@src/assets/icons/user.png';
import { apiReturnItem } from '@src/types/apiReturnItem';
import { MdModeEdit } from "react-icons/md";
import FloatingButton from "@src/components/FloatingButton";
import { Loading } from "@src/components/Loading";
import { LocalStorage } from "@infra/localStorage";
import MoreMenu from '@src/components/MoreMenu';
import sourceTracking from "@src/lib/sourceTracking";

//import { getAllRegisters } from '../../tests/mock/registerApi';
import api from '@infra/api';

function RegisterDetails(props: any){
    const navigate = useNavigate();
    let { registerId } = useParams();
    const [decryptedPassword, setDecryptedPassword] = useState('');
    const [displayPassword, setDisplayPassword] = useState(false);
    const [backTo, setBackTo] = useState("/");

    function formatDate(dateString: string): string {
        const date = new Date(dateString);
    
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
    
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    const editRegistry = () => {
        sourceTracking("editRegistry", "RegisterDetails");

        NavigateLib.navigate(navigate, '/registerEdit/'+registerId)
    };

    const btnSettings = {
        actions : [{ label: "Edit", icon: <MdModeEdit />, onClick: editRegistry }],
    };

    const backHistory = () => {
        sourceTracking("backHistory", "RegisterDetails");

        NavigateLib.backHistory(navigate, -1);
    }

    let emptyItem: apiReturnItem = {
        id: 0,
        user_id: 0,
        title: "",
        username: "",
        password: "",
        url: "",
        notes: "",
        icon: "default",
        childs: [],
        attach: [],
        created_at: "1900-01-01 00:00:00",
        updated_at: "1900-01-01 00:00:00",
    };

    const [currentItemData, setCurrentItemData] = React.useState(emptyItem);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = useState(false);
    const [parentId, setParentId] = useState(0);

    const getDataFromApi = async() => {
        sourceTracking("getDataFromApi", "RegisterDetails");

        try{
            let registerData = await api.get("/register/"+registerId);
            setDecryptedPassword(registerData.password);
            //registerData.password="* * * * * *";

            setCurrentItemData(registerData);
            setLoading(false);

            if (registerData.parent_id !== null){
                setBackTo("/registerList/"+registerData.parent_id);
                setParentId(registerData.parent_id);
            }
        } catch (e: any){
            if (e.response.status === 403){
                LocalStorage.remove("authToken");
                NavigateLib.navigate(navigate, "/login");
            } else {
                setLoading(false);
                setError(true);
            }
        }
    };

    const downloadAttach= async (itemId: any, filename: string) => {
        sourceTracking("downloadAttach", "RegisterDetails");

        try{
            await api.download("/attachDownload/"+itemId, filename);
        } catch (e: any){
            if (e.response.status === 403){
                LocalStorage.remove("authToken");
                NavigateLib.navigate(navigate, "/login");
            } else {
                setLoading(false);
                setError(true);
            }
        }
    }

    const showItem = async (itemId: number) => {
        sourceTracking("showItem", "RegisterDetails");

        NavigateLib.navigate(navigate, "/registerDetails/"+itemId);
    }

    const hidePassword = (password: string): string => {
        sourceTracking("hidePassword", "RegisterDetails");

        const regex = /./gm;

        let result = password.replace(regex, "*");
        if(password.length > 30){
            result = result.substr(0, 30);
        }

        return result;
    }

    const copyPasswordToClipboard = async (): Promise<void> => {
        sourceTracking("copyPasswordToClipboard", "RegisterDetails");

        await unsecuredCopyToClipboard(currentItemData.password+"")
    }

    const copyUsernameToClipboard = async (): Promise<void> => {
        sourceTracking("copyUsernameToClipboard", "RegisterDetails");

        await unsecuredCopyToClipboard(currentItemData.username+"");
    }

    const copyUrlToClipboard = async (): Promise<void> => {
        sourceTracking("copyUrlToClipboard", "RegisterDetails");

        await unsecuredCopyToClipboard(currentItemData.url+"")
    }

    const copyNotesToClipboard = async (): Promise<void> => {
        sourceTracking("copyNotesToClipboard", "RegisterDetails");

        await unsecuredCopyToClipboard(currentItemData.notes+"")
    }

    const togglePassword = () => {
        sourceTracking("togglePassword", "RegisterDetails");

        setDisplayPassword(!displayPassword);
    }

    useEffect(() => {
        sourceTracking("useEffect", "RegisterDetails");

        getDataFromApi();
    }, []);

    return (
        <>
            {loading ? <><Loading /></>: <>
                {error ? <DivError>Erro ao buscar dados da api</DivError> : <>
                    <ItemHeader title={currentItemData.title} backTo={backTo} registerId={registerId} parentId={parentId} setLoading={setLoading} />
                    <Container>
                        <IconContainer>
                            <IconItemList src={userIcon} />
                        </IconContainer>
                        <br/>
                        <DataContainer>
                            <br/>
                            <TitleItemDetails>Nome do usuário</TitleItemDetails>
                            <SubDataDetailsContainer>
                                {currentItemData.username && (
                                    <TypeItemList>{currentItemData.username}</TypeItemList>
                                )}
                            </SubDataDetailsContainer>
                        </DataContainer>
                        <MoreMenu id="userMenu" color="dark">
                            <nav>
                                <ul className="nav">
                                    <li>
                                        <span onClick={copyUsernameToClipboard} >Copiar Usuário</span>
                                    </li>
                                </ul>
                                </nav>
                        </MoreMenu>
                    </Container>
                    
                    <Container>
                        <IconContainer>
                            <IconItemList src={keyIcon} />
                        </IconContainer>
                        <DataContainer>
                            <TitleItemDetails>Senha</TitleItemDetails>
                            <SubDataDetailsContainer>
                                {displayPassword ? <TypeItemList>{currentItemData.password}</TypeItemList>
                                :
                                <>
                                    <TypeItemList>{hidePassword(currentItemData.password+"")}</TypeItemList>
                                </>}
                            </SubDataDetailsContainer>
                        </DataContainer>
                        <MoreMenu id="passwordMenu" color="dark">
                            <nav>
                                <ul className="nav">
                                    <li>
                                        <span onClick={copyPasswordToClipboard} >Copiar Senha</span>
                                    </li>
                                    <li>
                                        <span onClick={togglePassword}>{displayPassword ? <>Ocultar</> :<>Exibir</>} senha</span>
                                    </li>
                                </ul>
                                </nav>
                        </MoreMenu>
                    </Container>

                    <Container>
                        <IconContainer>
                            <IconItemList src={keyIcon} />
                        </IconContainer>
                        <DataContainer>
                            <TitleItemDetails>Url</TitleItemDetails>
                            <SubDataDetailsContainer>
                                {currentItemData.url && (
                                    <TypeItemList>{currentItemData.url}</TypeItemList>
                                )}
                            </SubDataDetailsContainer>
                        </DataContainer>
                        <MoreMenu id="urlMenu" color="dark">
                            <nav>
                                <ul className="nav">
                                    <li>
                                        <span onClick={copyUrlToClipboard} >Copiar Url</span>
                                    </li>
                                </ul>
                                </nav>
                        </MoreMenu>
                    </Container>

                    <Container>
                        <IconContainer>
                            <IconItemList src={keyIcon} />
                        </IconContainer>
                        <DataContainer>
                            <TitleItemDetails>Notas</TitleItemDetails>
                            <SubDataDetailsContainer>
                                {currentItemData.notes && (
                                    <TextAreaItemList dangerouslySetInnerHTML={{ __html: currentItemData.notes }} />
                                )}
                            </SubDataDetailsContainer>
                        </DataContainer>
                        <MoreMenu id="notesMenu" color="dark">
                            <nav>
                                <ul className="nav">
                                    <li>
                                        <span onClick={copyNotesToClipboard} >Copiar Notas</span>
                                    </li>
                                </ul>
                                </nav>
                        </MoreMenu>
                    </Container>

                    {(currentItemData.attach) ? <>
                        <Container>
                            <IconContainer>
                                <IconItemList src={attachmentIcon} />
                            </IconContainer>
                            <DataContainer>
                                <TitleItemDetails>Anexos</TitleItemDetails>
                                <SubDataDetailsContainer></SubDataDetailsContainer>
                            </DataContainer>
                            <ActionsIcon src={emptyIcon} />
                        </Container>
                        <AttachmentsDiv>
                            {(currentItemData.attach.length > 0) ? <>
                                        {currentItemData.attach.map((item: any) => {
                                            return(
                                                <React.Fragment key={item.id}>
                                                    <AttachmentItem  id={item.id} onClick={() => {downloadAttach(item.id, item.alias)}}>
                                                        <AttachmentText>
                                                            {item.alias}
                                                        </AttachmentText>
                                                        &nbsp;&nbsp;
                                                        <img style={{position: 'absolute', right: '15px'}} src={downloadIcon} />
                                                    </AttachmentItem>
                                                </React.Fragment>
                                            )
                                        })}
                            </> : <></>}
                        </AttachmentsDiv>
                    </> : <></>}
                    
                    <Container>
                        <IconContainer>
                            <IconItemList src={createdAtIcon} />
                        </IconContainer>
                        <DataContainer>
                            <TitleItemDetails>Criado</TitleItemDetails>
                            <SubDataDetailsContainer>
                                <TypeItemList>{formatDate(currentItemData.created_at)}</TypeItemList>
                            </SubDataDetailsContainer>
                        </DataContainer>
                        <ActionsIcon src={emptyIcon} />
                    </Container>
                    <Container>
                        <IconContainer>
                            <IconItemList src={updatedAtIcon} />
                        </IconContainer>
                        <DataContainer>
                            <TitleItemDetails>Modificado</TitleItemDetails>
                            <SubDataDetailsContainer>
                                <TypeItemList>{formatDate(currentItemData.updated_at)}</TypeItemList>
                            </SubDataDetailsContainer>
                        </DataContainer>
                        <ActionsIcon src={emptyIcon} />
                    </Container>

                    <FloatingButton settings={btnSettings} />
                </>}
            </>}
        </>
    )
}

export { RegisterDetails };