import React, {useState, useEffect} from 'react';
import { Loading } from "../../components/Loading";
import sourceTracking from "../../lib/sourceTracking";
import { Container,  DivError } from './style';
import { Input } from '@src/components/Input';
import { Legend } from '@src/components/Graph';
import { NavigateLib } from '@src/lib/navigate';
import titleIcon from '@src/assets/icons/title.png';
import api from '@infra/api';
import './localStyle.css';
import { Button } from '@src/components/Button';
import ModalDialog from '@src/components/ModalDialog';
import { LocalStorage } from "@infra/localStorage";
import { useNavigate } from 'react-router-dom';
import { StatsHeader } from '@src/components/Headers/StatsHeader';

export function Stats(props: any){
    const [loading, setLoading] = React.useState(true);
    const [title, setTitle] = useState('');
    const [graphData, setGraphData] = useState<any[]>([]);
    const maxGraphRegisters = 4;
    const [searchResult, setSearchResult] = useState<any[]>([]);
    const [modalSettings, setModalSettings] = useState({});
    const [registerId, setRegisterId] = useState(0);
    const [saveError, setSaveError] = useState("");
    const [idsSearch, setIdsSearch] = useState([]);

    const navigate = useNavigate();
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
    const RADIAN = Math.PI / 180;

    const searchByIds = async (ids: any) => {
        if (ids.length > 0){
            setLoading(true);
            let result = await api.get("/registerMultipleFind/["+ids+"]");
            setLoading(false);
            setSearchResult(result);
        }
    }

    useEffect(() => {
        sourceTracking("useEffect", "Stats");
        searchByIds(idsSearch);
    }, [idsSearch])

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index
      }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
          <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
      };

    const getDataFromApi = async() => {
        sourceTracking("getDataFromApi", "Stats");

        let statsData = await api.get("/stats?maxNumRegisters="+4);

        let tempGraphsData: any[] = [];
        statsData.map((item: any) => {
            let name = item.password;
            if (name.length > 20){
                name = item.password.substring(0,19)+"...";
            }

            tempGraphsData.push({
                name: name,
                value: item.counter,
                ids: item.ids
            })
        })

        setGraphData(tempGraphsData);
        setLoading(false);
        //let statsData = await api.get("/stats?maxNumRegisters=2");
    }

    useEffect(() => {
        sourceTracking("useEffect", "Stats");
        getDataFromApi();
    },[])

    const handleChangeTitle = (value: string) => {
        sourceTracking("handleChangeTitle", "Stats");
        //setTitle(value);
    }

    const buscarAction = () => {
        setSearchResult([
            {
                id: 1,
                title: 'exampleRegister'
            }
        ])
    }

    const alterarAction = async () => {
        let passwordFromHtml = (document.getElementById('passwordInput') as HTMLInputElement) ? (document.getElementById('passwordInput') as HTMLInputElement).value : null;
        let passwordConfirmFromHtml = (document.getElementById('passwordConfirmInput') as HTMLInputElement) ? (document.getElementById('passwordConfirmInput') as HTMLInputElement).value : null;

        if (passwordFromHtml !== passwordConfirmFromHtml){
            alert("As senhas não coincidem")
            setLoading(false);
            return;
        }

        try{
            await api.put("/register/updatePassOnly/"+registerId, {
                "password": passwordFromHtml
            });
        } catch(e: any){
            if (e.response.status === 403){
                // TODO: Exibir a tela de login e depois continuar a operação, evitando perder os dados já editados
                LocalStorage.remove("authToken");
                await NavigateLib.navigate(navigate, '/login');
            }

            setSaveError("Erro ao salvar dados");
            setLoading(false);
        }
        setModalSettings({open: false});
    }

    const alterarSenha = (obj: any) => {
        setRegisterId(obj.id);
        setModalSettings({
            open: true,
            title: 'Alterar senha',
            message: <>
                <span>Alterando a senha de {obj.title}</span>
                <br/><br/>
                <div>
                    <Input
                            id="passwordInput"
                            placeholder="Nova senha"
                            label="Nova senha"
                            type="password"
                            locked={false}
                    />
                    <br/>
                    <Input
                            id="passwordConfirmInput"
                            placeholder="Confirmação de senha"
                            type="password"
                            label="Confirmação de senha"
                            locked={false}
                    />
                </div>
                <br/><br/><br/>
            </>,
            buttons: [
                {
                    label: 'Salvar',
                    onClick: async () => {
                        alterarAction();
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: async () => {
                        setModalSettings({open: false});
                    },
                    style: {
                        "backgroundColor": "#777"
                    }
                }
            ]
        });
    }

    return (
        <>
            {loading ? <><Loading /></>: <>
            {saveError !== "" ? <>
                    <br/>
                    <DivError>
                        {saveError}
                    </DivError>
            </>: <></>}
            <StatsHeader setLoading={setLoading} />
                <Container>
                    <br/>
                    <div style={{position: 'relative', width: '100%', paddingBottom: '250px'}}>
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                bottom: 0,
                                top: 0,
                            }}
                        >
                            <p style={{position: 'relative',top:0, color: "#555", fontWeight: "bold", fontSize: '0.78em'}}>
                                Top {maxGraphRegisters} senhas mais utilizadas
                            </p>
                            <Legend setIdsSearch={setIdsSearch} data={graphData} colors={COLORS} />
                        </div>
                    </div>
                
                    {/*
                    <br/><br/>
                    <div style={{display: 'flex'}}>
                       
                        <div style={{paddingLeft: '10px', width: "65%"}}>
                            <Input
                                id="titleInput"
                                placeholder="Buscar registros por senha"
                                label="Buscar registros por senha"
                                onChange={handleChangeTitle}
                                value={title}
                                locked={false}
                            />
                        </div>
                        
                        <div>
                            <Button
                                id="buscarPorSenhaBtn"
                                style={{position: 'relative', left: '10px', top: '8px'}}
                                title="Buscar"
                                onClick={buscarAction}
                            />
                        </div>    
                    </div>
                    */}

                    <br/><br/><br/><br/><br/><br/>
                    <div style={{display: 'flex'}}>
                        <div style={{position: 'relative', top: '10px', flex: 1}}>
                            {searchResult.map((item: any, index: number) => (
                                <React.Fragment key={index}>
                                    <ul style={{listStyleType: "none", padding: '12px'}}>
                                        <li style={{fontWeight: "bold", display: "flex"}}>
                                            <div style={{flex: 1, position: "relative", top: "16px"}}>
                                                {item.title}
                                            </div>
                                            <div>
                                                <Button
                                                    id={"alterarSenhaBtn"+index}
                                                    style={{position: 'relative', right: '0px', top: '8px', padding: "5px 10px 5px 10px", fontSize: "0.8em"}}
                                                    title="Alterar"
                                                    onClick={() => {alterarSenha(item)}}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <ModalDialog settings={modalSettings} />
                </Container>
            </>
            }
        </>
    )
}