class NavigateLib {
    public static navigate(navigator: any, route: any): void {
        navigator(route);
        navigator(0);
    }

    public static backHistory(navigator: any, step: number = 1){
        navigator(step);
    }

    public static query(locator: any, param: string) {
        return new URLSearchParams(locator.search).get(param);
    }

    public static removeQueryParams(searchParams: any, setSearchParams: any, param: string) {
        const paramFound = searchParams.get(param);

        if (paramFound) {
            searchParams.delete(param);
            setSearchParams(searchParams);
        }
    }
}

export { NavigateLib }