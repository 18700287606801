import React, {useEffect} from 'react';
import { StatsHeaderContainer, StatsHeaderTitle, StatsHeaderToolImg  } from './style';
import { NavigateLib } from '@src/lib/navigate';
import { useNavigate } from 'react-router-dom';
import backIcon from '@src/assets/icons/back.png';
import MoreMenu from '@src/components/MoreMenu';
import sourceTracking from "@src/lib/sourceTracking";

function StatsHeader(props: any){
    const navigate = useNavigate();

    const backHistory = () => {
        sourceTracking("backHistory", "StatsHeader");
        props.setLoading(true);
        NavigateLib.navigate(navigate, props.backTo);
    }

    const goHome = () => {
        sourceTracking("goHome", "StatsHeader");
        props.setLoading(true);
        NavigateLib.navigate(navigate, '/')
    }

    useEffect(() => {
        sourceTracking("useEffect", "StatsHeader");
    }, []);

    return (
        <>
            <StatsHeaderContainer>
                {props.displayBackButton ? <div onClick={backHistory}><StatsHeaderToolImg src={backIcon} /></div>: <></>}
                <StatsHeaderTitle style={{position: 'relative'}}>
                    {props.title}
                </StatsHeaderTitle>
                <MoreMenu id="topRightMenu" color="light">
                    <nav>
                        <ul className="nav">
                            <li>
                                <span onClick={goHome}>Home</span>
                            </li>
                        </ul>
                        </nav>
                </MoreMenu>
                
            </StatsHeaderContainer>
        </>
    )
};

export { StatsHeader };