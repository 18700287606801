import styled from 'styled-components';

const Container = styled.div`
    padding: 12px;
`

const LogoImg = styled.img`
    position: absolute;
    max-width: 128px;
    right: 0;
    margin-top: 2em;
`;

const LoginHeaderTitle = styled.span`
    position: absolute;
    margin-top: 3.8em;
    font-size: 2em;
`;

const LoginDataContainer = styled.div`
    position: absolute;
    left: 5%;
    width: 90%;
`;

const LoginErrorContainer = styled.div`
    padding: 10px;
    color:  #fff;
    background: rgba(255,37,37,0.85);
    font-weight: bold;
    margin-bottom: 15px;
`;

const LoginHeaderContainer = styled.div`
    padding: 10px;
    color:  #fff;
    background: rgba(42,7,94,0.65);
    font-weight: bold;
    height: 10em;
    margin-bottom: 15px;
`;

const ForgotPasswordSpan = styled.span`
    color:  #33c;
    font-weight: bold;
    cursor: pointer;
    text-align: center;

`;

const PasswordContainerText = styled.div`
    display: flex;
    width: 100%;
    height: 10vh;
`;

const HalfWidthContainer = styled.div`
    flex: 1; /* Cada div ocupa 50% da largura */
    display: flex; /* Torna o container flexível para seus filhos */
    align-items: center; /* Centraliza verticalmente os itens */
    justify-content: center; /* Centraliza horizontalmente os itens, se desejado */
    padding: 10px; /* Adiciona algum espaçamento interno, se necessário */
    box-sizing: border-box; /* Inclui padding e borda no cálculo da largura */
`;

export { Container, LoginHeaderContainer, LogoImg, LoginHeaderTitle, LoginDataContainer, LoginErrorContainer, ForgotPasswordSpan, PasswordContainerText, HalfWidthContainer };