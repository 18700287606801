import { toast, Bounce } from 'react-toastify';

const unsecuredCopyToClipboard = async (text: string, displayDefaultSuccessToast = true) => {
    const p = new Promise<void>((resolve, reject) => {
        const textArea = document.createElement("textarea");
        textArea.value=text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        
        try{
            document.execCommand('copy')

            if (displayDefaultSuccessToast){
                toast.success("Conteúdo copiado", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } catch(err: any){
            toast.error('Erro ao copiar conteúdo', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });

            reject('Unable to copy to clipboard')
        }
        document.body.removeChild(textArea)

        resolve();
    });

    await p;
};

export { unsecuredCopyToClipboard }