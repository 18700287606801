import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";
import { RegisterList } from "./views/RegisterList";
import { RegisterDetails } from "./views/RegisterDetails";
import { RegisterEdit } from "./views/RegisterEdit";
import { Stats } from "./views/Stats";
import { Login } from "./views/Login";
import { RouteManager } from "./lib/navigate/routeManager";
import React, { useState, useEffect } from 'react';
import { LocalStorage } from "./infra/localStorage";
import { Loading } from "./components/Loading";
import sourceTracking from "./lib/sourceTracking";
import { Log } from "@infra/log";
import { AppContextProvider } from "./contexts/appContext";

function Router() {
    const [loading, setLoading] = useState(true);
    const [signed, setSigned] = useState(false);  

    const loadRouter = async() => {
        let path = window.location.pathname;
       
        if (!signed) {
            (async () => {
                let authToken = await LocalStorage.get("authToken");
    
                if (authToken){
                    setSigned(true);
                    if (path === "/login"){
                        window.location.href = "/";
                    }
                } else {
                    if (path !== "/login"){
                        window.location.href = "/login";
                    }
                }
            })();
        }

        setLoading(false);
        sourceTracking("useEffect", "Router");
    }

    useEffect(() => {
        loadRouter();
    },[signed]);

    return (
        loading ? <><Loading /></> : <>
            {
                signed ? <AppContextProvider>
                    <BrowserRouter>
                        <RouteManager />
                        <Routes>
                            <Route path="/" element={<RegisterList />} />
                            <Route path="/login" element={<Login loading={loading} setLoading={setLoading} />} />
                            <Route path="/registerList/:registerId" element={<RegisterList />} />
                            <Route path="/registerDetails/:registerId" element={<RegisterDetails />} />
                            <Route path="/registerEdit/:registerId" element={<RegisterEdit />} />
                            <Route path="/stats" element={<Stats />} />
                            <Route path="/registerAdd" element={<RegisterEdit />} />
                        </Routes>
                    </BrowserRouter>
                </AppContextProvider> : <>
                    <BrowserRouter>
                        <RouteManager />
                        <Routes>
                            <Route path="/login" element={<Login loading={loading} setLoading={setLoading} />} />
                        </Routes>
                    </BrowserRouter>
                </>
            }
        </>
    );
}

export default Router;