function generateRandomPassword(length: number = 22): string {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
  const password = [];
  const cryptoObj = window.crypto || (window as any).msCrypto; // Compatibilidade com navegadores mais antigos

  for (let i = 0; i < length; i++) {
      const randomIndex = cryptoObj.getRandomValues(new Uint32Array(1))[0] % charset.length;
      password.push(charset[randomIndex]);
  }

  return password.join('');
}

export default generateRandomPassword;