import React, { useState, createContext, ReactNode, useRef, useEffect } from 'react';

export const AppContext  = createContext({} as any);

interface AppContextProviderProps {
    children: ReactNode
}

export function AppContextProvider({ children, }: AppContextProviderProps){
    const [actionBroadcast, setActionBroadcast] = useState({});
    const [outsideMenuClickDetected, setOutsideMenuClickDetected] = useState({status: false, origin: 'appContext'});

    useEffect(() => {
    }, [outsideMenuClickDetected])

    useEffect(() => {
        const handleClick = (e: any) => {
            if (e.srcElement.className !== "menu-icon"){
                setOutsideMenuClickDetected({status: true, origin: 'appContext'});
            }
        }

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    },[])    

    return (
        <AppContext.Provider value={{ actionBroadcast, setActionBroadcast, outsideMenuClickDetected, setOutsideMenuClickDetected }}>
            <div>
                {children}
            </div>
        </AppContext.Provider>
    )
}