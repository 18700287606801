import styled from 'styled-components';

const HomeHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding-left: 10px;
    background-color: #56009e;
    color: #fff;
    font-weight: bold;
`

const HomeHeaderTitle = styled.div``;

const HomeHeaderToolImg = styled.img`
    flex: 1;
    max-width: 32px;
    max-height: 32px;
`;

export { HomeHeaderContainer, HomeHeaderToolImg, HomeHeaderTitle };