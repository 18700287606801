import React, {useState, useEffect} from 'react';
import api from '@infra/api';
import { ItemList } from '@src/components/ItemList';
import { ListHeader } from '@src/components/Headers/ListHeader';
import { useNavigate, useParams } from "react-router-dom";
// import { getAllRootRegisters, getRegistersById } from '../../tests/mock/registerApi';
import { apiReturnItem } from '@src/types/apiReturnItem';
import { NavigateLib } from '@src/lib/navigate';
import { Loading } from "@src/components/Loading";
import { LocalStorage } from "@infra/localStorage";
import { DivError, DivSearchStatus } from './style';
import { MdOutlineAdd, MdFolder, MdOutlineVpnKey } from "react-icons/md";
import FloatingButton from "@src/components/FloatingButton";
import sourceTracking from "@src/lib/sourceTracking";

export function RegisterList(props: any) {
    const navigate = useNavigate();
    let { registerId } = useParams();
    let emptyList: apiReturnItem[] = [];

    const [registersList, setRegistersList] = React.useState(emptyList);
    const [title, setTitle] = React.useState("Moc Password");
    const [displayBackButton, setDisplayBackButton] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [backTo, setBackTo] = React.useState("/");
    const [error, setError] = useState(false);
    const [registersWithFind, setRegistersWithFind] = useState(false);
    const [parentId, setParentId] = useState(null);

    const addRegistry = () => {
      sourceTracking("addRegistry", "RegisterList");

      if (registerId){
        NavigateLib.navigate(navigate, '/registerAdd?parent_id='+registerId)
      } else {
        NavigateLib.navigate(navigate, '/registerAdd')
      }
    };

    const addGroup = () => {
      sourceTracking("addGroup", "RegisterList");

      if (registerId){
        NavigateLib.navigate(navigate, '/registerAdd?parent_id='+registerId+'&group=true')
      } else {
        NavigateLib.navigate(navigate, "/registerAdd?group=true")
      }
    };

    const btnSettings = {
      mainIcon: <MdOutlineAdd />,
      actions : [
        { label: "Add Group", icon: <MdFolder />, onClick: addGroup },
        { label: "Add Item", icon: <MdOutlineVpnKey />, onClick: addRegistry }
      ],
    };

    const setRegistersFind = async(result: any) => {
      sourceTracking("setRegistersFind", "RegisterList");

      setRegistersWithFind(true);
      setRegistersList(result);
    }

    const setLoadingWithSearch = async(value: boolean) => {
      sourceTracking("setLoadingWithSearch", "RegisterList");

      setLoading(value);
    }

    const showItem = async (itemData: apiReturnItem) => {
      sourceTracking("showItem", "RegisterList");

      setLoading(true);
      if (itemData.childs.length !== 0){
        NavigateLib.navigate(navigate, "/registerList/"+itemData.id);
      } else {
        NavigateLib.navigate(navigate, "/registerDetails/"+itemData.id);
      }
    }

    useEffect(() => {
        sourceTracking("useEffect", "RegisterList");

        const getDataFromApi = async(registerIds?: string) => {
          sourceTracking("getDataFromApi", "RegisterList");

          try{
            if (!registerId){
              let result = await api.get("/register");
              //let registersList = await getAllRootRegisters();
              setRegistersList(result);
              setLoading(false);

              if (result.parent_id !== null){
                setBackTo("/registerList/"+result.parent_id);
                setParentId(result.parent_id);
              }
              return;
            }
        
            let result = await api.get("/registerGroup/"+registerId);
            setTitle(result.title);
            setDisplayBackButton(true);
            setRegistersList(result.items);
            setLoading(false);

            if (result.parent_id !== null){
                setBackTo("/registerList/"+result.parent_id);
                setParentId(result.parent_id);
            }
          } catch (e: any){
            switch (e.response.status) {
              case 403:
                LocalStorage.remove("authToken");
                NavigateLib.navigate(navigate, "/login");
                break;

              case 404:
                setRegistersList([]);
                setLoading(false);
                break;
            
              default:
                setLoading(false);
                setError(true);
                break;
            }
          }
      };
      
      if (parseInt(registerId+"") !== 0){
        getDataFromApi(registerId);
      }
    }, [registerId, navigate]);
    
    return (
      <>
        {loading ? <><Loading /></>: <>
          {error ? <DivError>Erro ao buscar dados da api</DivError> : <>
            <ListHeader
              title={title}
              displayBackButton={displayBackButton}
              backTo={backTo}
              setRegistersFind={setRegistersFind}
              setLoading={setLoadingWithSearch}
              registerId={registerId}
              parentId={parentId}
            />
            {
              registersWithFind ? <>
                <DivSearchStatus>Foram encontrados {registersList.length} resultado(s) na busca</DivSearchStatus>
              </> : <></>
            }
            <br/>
            {
              registersList.map((register) => {
                return (
                  <React.Fragment key={register.id}>
                    <ItemList onClick={() => {showItem(register)}} childs={register.childs} title={register.title} parent_id={register.parent_id} icon={register.icon} />
                  </React.Fragment>
                )}
              )
            }
            <FloatingButton settings={btnSettings} />
          </>}
        </>}
      </>
    );
}