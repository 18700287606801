import { StyledButton } from './style';
const HtmlToReactParser = require('html-to-react').Parser;

function Button(props: any){
    const htmlToReactParser = new HtmlToReactParser();
    const id = props.id ? props.id : "";
    const className = props.id ? props.className : "";

    return (
        <StyledButton id={id} className={className} style={props.style} onClick={props.onClick}>
            {props.html ? <>
                {htmlToReactParser.parse(props.html)}
            </> : <>
                {props.title}
            </>}
        </StyledButton>
    )
}

export { Button }