import React, {useState, useEffect, useRef} from 'react';
import { ModalBackground, ModalContainer, ModalTitle, ModalMessage, ModalButtonsContainer } from './style';
import { Button } from '../Button';

function ModalDialog(props: any){
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [buttons, setButtons] = useState([]);
    const open = useRef(false);
    const [openStatus, setOpenStatus] = useState(false);

    const onClickHandle = async (e: any) => {
        await e();
    }

    useEffect(() => {
        if (props){
            let settings = props.settings;
            open.current = settings.open;
            setOpenStatus(open.current);

            if (settings.title){
                setTitle(settings.title);
            }
            if (settings.message){
                setMessage(settings.message);
            }
            if (settings.buttons){
                setButtons(settings.buttons);
            }
        }
    }, [props]);

    return(
        <>
            {
                openStatus === true ? <>
                    <ModalBackground>
                        <ModalContainer>
                            <ModalTitle>{title}</ModalTitle>
                            <br/>
                            <ModalMessage>{message}</ModalMessage>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <ModalButtonsContainer>
                                {buttons.map((button: any, index: any) => {
                                    return(
                                        <Button key={index} style={button.style} title={button.label} onClick={() => { onClickHandle(button.onClick) }}></Button>    
                                    )
                                })}
                            </ModalButtonsContainer>
                        </ModalContainer>
                    </ModalBackground>
                </> : <></>
            }
        </>
    );
}

export default ModalDialog;