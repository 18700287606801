import { Env } from "@infra/env";
import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { ApiInterface } from '../../apiInterface';
import Auth from "@infra/auth";
import { Log } from "@infra/log";

class AxiosApi implements ApiInterface {
  public async getInstance(){
    const accessToken = await Auth.getLocalAuthToken();
    let mockApiEnv = await Env.get("MOCK_API");
    let baseURL = await Env.get("BASE_URL");

    let api = null;

    if (mockApiEnv !== "true") {
      api = axios.create({
        baseURL: baseURL,
      });
      api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    } else {
      Log.error("MOCK_API not implemented yet");
      process.exit(1);
      //let mock = new MockAdapter(axios, { delayResponse: 300 });
      //mockLoader(mock);
    }

    api.interceptors.request.use(
      (config: any) => {
        if (accessToken !== "") {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
      (err) => Promise.reject(err)
    );

    return api;
  }

  public async get(url: string, responseType: string = "", headers = {}) {
    let api = null;
    api = await this.getInstance();

    let response = await api({
          method: "get",
          url: url,
          headers: headers
        }).then(function (response: any) {
          return response.data;
    });

    return response;
  }

  public async download(url: string, targetName: string, headers = {}){
    let api = null;
    api = await this.getInstance();

    api({
        url: url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', targetName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
  }

  public async put(url: string, data: {}, headers = {}): Promise<any> {
    let api = null;
    api = await this.getInstance();

    let response = await api({
          method: "put",
          url: url,
          headers: headers,
          data: data
        }).then(function (response: any) {
          return response.data;
    });

    return response;
  }

  public async post(url: string, data: {}, headers = {}): Promise<any> {
    let api = null;
    api = await this.getInstance();

    let response = await api({
          method: "post",
          url: url,
          headers: headers,
          data: data
        }).then(function (response: any) {
          return response.data;
    });

    return response;
  }

  public async delete(url: string, headers = {}): Promise<any>{
    let api = null;
    api = await this.getInstance();

    let response = await api({
      method: "delete",
      headers: headers,
      url: url
    }).then(function (response: any) {
      return response.data;
    });

    return response;
  }
}

export default AxiosApi;