import React, {useState, useEffect} from 'react';

import { HomeHeaderContainer, HomeHeaderTitle, HomeHeaderToolImg  } from './style';
import { MoveHeaderContainer } from '../style'
import findIcon from '@src/assets/icons/find.png';
import backIcon from '@src/assets/icons/back.png';
import moveIcon from '@src/assets/icons/move.png';
import cancelIcon from '@src/assets/icons/cancel.png';
import menuMoreHeaderIcon from '@src/assets/icons/menuMoreHeader.png';
import { NavigateLib } from '@src/lib/navigate';
import { useNavigate } from 'react-router-dom';
import MoreMenu from '@src/components/MoreMenu';
import ModalDialog from '@src/components/ModalDialog';
import api from "@infra/api";
import { LocalStorage } from "@infra/localStorage";
import sourceTracking from "@src/lib/sourceTracking";

function ItemHeader(props: any){
    const navigate = useNavigate();
    const [modalSettings, setModalSettings] = useState({});
    const [registerIdToMove, setRegisterIdToMove] = useState(0);

    const backHistory = () => {
        sourceTracking("backHistory", "ItemHeader");
        props.setLoading(true);
        NavigateLib.navigate(navigate, props.backTo);
    }

    const markToBeMoved = async () => {
        sourceTracking("markToBeMoved", "ItemHeader");
        await LocalStorage.set('toBeMoved', props.registerId);
        setRegisterIdToMove(parseInt(props.registerId));
    }

    const checkMoveFlag = async () => {
        sourceTracking("checkMoveFlag", "ItemHeader");
        let toBeMovedTmp = await LocalStorage.get('toBeMoved');
        let toBeMoved = parseInt(toBeMovedTmp+"");
        if (toBeMoved){
            setRegisterIdToMove(toBeMoved);
        }
    }

    const doMove = async() => {
        sourceTracking("doMove", "ItemHeader");
        props.setLoading(true);
        await api.put("/register/"+registerIdToMove, {
            "parent_id": props.registerId
        });
    }

    const cancelMove = async() => {
        sourceTracking("cancelMove", "ItemHeader");
        LocalStorage.remove('toBeMoved');
        setRegisterIdToMove(0);
    }

    const goHome = () => {
        sourceTracking("goHome", "ItemHeader");
        props.setLoading(true);
        NavigateLib.navigate(navigate, '/')
    }

    const openFind = () => {
        sourceTracking("goBuscar", "ItemHeader");
        props.setLoading(true);
        NavigateLib.navigate(navigate, '/?buscar=true')
    }

    useEffect(() => {
        sourceTracking("useEffect", "ItemHeader");
        checkMoveFlag();
    }, []);

    const remove = () => {
        sourceTracking("removeModal", "ItemHeader");
        setModalSettings({
            open: true,
            title: 'Apagar permanentemente',
            message: 'Deseja realmente apagar esta entrada ?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        try{
                            sourceTracking("removeAction", "ItemHeader");
                            props.setLoading(true);

                            await api.delete("/register/"+props.registerId);

                            setTimeout(async () => {
                                if (props.parentId !== 0){
                                    let result = await api.get("/register/"+props.parentId);
                                    if (result.childs.length === 0){
                                        if (result.parent_id === null){
                                            await NavigateLib.navigate(navigate, '/');        
                                        } else {
                                            await NavigateLib.navigate(navigate, '/registerList/'+result.parent_id);
                                        }
                                    } else{
                                        await NavigateLib.navigate(navigate, '/registerList/'+props.parentId);
                                    }
                                } else {
                                    await NavigateLib.navigate(navigate, '/');
                                }
                            }, 1000);

                        } catch (e: any){
                            if (e.response.status === 403){
                                LocalStorage.remove("authToken");
                                NavigateLib.navigate(navigate, "/login");
                            } else {
                                alert("Erro ao apagar entrada");
                            }
                        }

                        setModalSettings({open: false});
                    }
                },
                {
                    label: 'Não',
                    onClick: async () => {
                        setModalSettings({open: false});
                    }
                }
            ]
        });
    }

    return (
        <>
            {registerIdToMove !== 0 ? <>
                <HomeHeaderContainer>
                    <div onClick={backHistory}>
                        <HomeHeaderToolImg src={backIcon} />
                    </div>
                    <HomeHeaderTitle>
                        <div onClick={cancelMove} style={{float: 'left', marginRight: '30px'}}>
                            <HomeHeaderToolImg style={{paddingRight: '0px', right: '15px', position: 'relative', top: '3px'}} src={cancelIcon} />
                            <span style={{position: 'relative', top: '-2px'}}>
                                Cancelar
                            </span>
                        </div>
                    </HomeHeaderTitle>
                    <div></div>
                </HomeHeaderContainer>
            </>: <>
                <HomeHeaderContainer>
                    <div onClick={backHistory}>
                        <HomeHeaderToolImg src={backIcon} />
                    </div>
                    <HomeHeaderTitle>
                        {props.title}
                    </HomeHeaderTitle>
                    <MoreMenu id="topRightMenu" color="light">
                        <nav>
                            <ul className="nav">
                                <li>
                                    <span onClick={goHome}>Home</span>
                                </li>
                                <li>
                                    <span onClick={openFind}>Buscar</span>
                                </li>
                                <li>
                                    <span onClick={remove} >Excluir</span>
                                </li>
                                <li>
                                    <span onClick={markToBeMoved} >Mover</span>
                                </li>
                            </ul>
                            </nav>
                    </MoreMenu>
                    {/*<HomeHeaderToolImg style={{padding: '12px'}}  src={menuMoreHeaderIcon} />*/}
                    <ModalDialog settings={modalSettings} />
                </HomeHeaderContainer>
            </>}
        </>
    )
};

export { ItemHeader };