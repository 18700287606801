import React, {useState, useEffect} from 'react';
import { LoginHeaderContainer, LogoImg, LoginHeaderTitle, LoginDataContainer, LoginErrorContainer, ForgotPasswordSpan, PasswordContainerText, HalfWidthContainer } from './style';
import logoIcon from '@src/assets/icons/logo.png';
import { Input } from '@src/components/Input';
import { Button } from '@src/components/Button';
import { Checkbox } from '@src/components/Checkbox';
import api from "@infra/api";
import { LocalStorage } from "@infra/localStorage";
import { NavigateLib } from '@src/lib/navigate';
import { useNavigate } from 'react-router-dom';
import {handleKeyPress, keyCodeList} from "@src/lib/keypress";
import sourceTracking from "@src/lib/sourceTracking";

function Login({ loading, setLoading }: any){
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const loadEmailField = async () => {
        sourceTracking("loadEmailField", "Login");

        let emailFromLocalStorage = await LocalStorage.get("loginEmail")+"";

        if (emailFromLocalStorage !== "null"){
            (document.getElementById('emailInput') as HTMLInputElement).value = emailFromLocalStorage;
        }
    }

    const recuperarSenhaModal = async () => {
        let email = prompt("Se você não sabe a senha consigo enviar isso para seu e-mail caso você esteja cadastrado na lista de e-mails permitidos.\n\nDICA: as iniciais das pessoas que tem acesso à esse sistema são: M, B, E, S. \n\nDigite seu e-mail: ");
        
        sourceTracking("login", "recuperarSenha");

        if (!email){
            return;
        }

        try{
            let apiReturn = JSON.parse(await api.get("/sendCredentialsWhenOver/"+email));

            if (apiReturn.error === ""){
                alert("E-mail enviado com sucesso. Verifique sua caixa de e-mail e o SPAM");
                return;
            } else{
                if (apiReturn.error === "NOT_AUTH"){
                    alert("Seu e-mail não consta na lista. Verifique seu e-mail e tente novamente");
                    return;
                }

                if (apiReturn.credentials){
                    let credentials = apiReturn.credentials;
                    alert(
                        "Erro geral ao tentar verificar o usuário ou enviar o e-mail.\n\n"+
                        "Como último recurso irei te dizer quais são as credenciais de acesso:\n\n"+
                        "E-mail: "+credentials.email+"\n"+
                        "Senha: "+credentials.password+"\n"
                    );
                    return;
                }
            }
        } catch(e: any){
            alert(e)
            setError(true);
        }
        setLoading(false);
    }

    const onEnterPress = (event: any) => {
        sourceTracking("onEnterPress", "Login");

        handleKeyPress(event, keyCodeList.ENTER, () => {
            (document.getElementById('acessarBtn') as HTMLInputElement).click()
        });
    }

    const login = async () => {
        sourceTracking("login", "Login");

        let email = (document.getElementById('emailInput') as HTMLInputElement).value;
        let password = (document.getElementById('passwordInput') as HTMLInputElement).value;
        let saveEmail = (document.getElementById('saveEmail') as HTMLInputElement).checked;

        try{
            setLoading(true);

            let apiReturn = await api.get("/login?email="+email+"&password="+password);

            if (apiReturn.result === true){
                LocalStorage.set("authToken", apiReturn.token);

                if (saveEmail){
                    LocalStorage.set("loginEmail", email);
                }

                setError(false);
                NavigateLib.navigate(navigate, "/");
            } else{
                setError(true);
            }
        } catch(e: any){
            alert(e)
            setError(true);
        }
        setLoading(false);
    }

    useEffect(() => {
        sourceTracking("useEffect", "Login");

        setLoading(false);
        loadEmailField();
    }, [loading]);

    return (
        <>
            <LoginHeaderContainer>
                <LoginHeaderTitle>Acessar dados</LoginHeaderTitle>
                <LogoImg src={logoIcon} />
            </LoginHeaderContainer>
            <LoginDataContainer>
                <br/>
                <Input
                        id="emailInput"
                        placeholder="E-mail"
                        label="E-mail"
                        autocomplete="on"
                        onKeyPress={onEnterPress}
                        locked={false}
                    />
                <br/>
                <Input
                        id="passwordInput"
                        placeholder="Senha"
                        label="Senha"
                        type="password"
                        onKeyPress={onEnterPress}
                        locked={false}
                    />
                <PasswordContainerText>
                    <HalfWidthContainer>
                        <Checkbox type="checkbox" id="saveEmail" title={"Salvar E-mail"} checked />
                    </HalfWidthContainer>
                    <HalfWidthContainer>
                        <ForgotPasswordSpan onClick={recuperarSenhaModal}>Não sei a senha</ForgotPasswordSpan>
                    </HalfWidthContainer>
                </PasswordContainerText>
                {error ? <>
                    <br/>
                    <LoginErrorContainer>
                        Usuário/senha inválido(s)
                    </LoginErrorContainer>
                </>: <></>}

                <Button id="acessarBtn" style={{position: 'absolute', right: '0'}} title="Acessar" onClick={login} />

                <br />
            </LoginDataContainer>
        </>
    )
}

export { Login };