import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { NavigateLib } from '@src/lib/navigate';
import { ItemEditHeader } from '@src/components/Headers/ItemEditHeader';
import { Input } from '@src/components/Input';
import { Button } from '@src/components/Button';
import { TextArea } from '@src/components/TextArea';
import { Container, StaticIcon, DivError, AttachmentsDiv, AttachmentItem, AttachmentText } from './style';
import userIcon from '@src/assets/icons/user.png';
import keyIcon from '@src/assets/icons/key.png';
import globeIcon from '@src/assets/icons/globe.png';
import notesIcon from '@src/assets/icons/notes.png';
import titleIcon from '@src/assets/icons/title.png';
import attachmentIcon from '@src/assets/icons/attachment.png';
import visibilityIcon from '@src/assets/icons/visibility.png';
import passwordIcon from '@src/assets/icons/password.png';
import deleteIcon from '@src/assets/icons/delete.png';
//import { getRegistersById } from '../../tests/mock/registerApi';
import { Loading } from "@src/components/Loading";
import api from '@infra/api';
import { LocalStorage } from "@infra/localStorage";
import ModalDialog from '@src/components/ModalDialog';
import './localStyle.css';
import sourceTracking from "@src/lib/sourceTracking";
import { generateRandomPassword } from '@infra/password';
import { toast, Bounce } from 'react-toastify';
import "./styles.css";
import { unsecuredCopyToClipboard } from '@src/lib/unsecuredCopyToClipboard';

function RegisterEdit(){
    const navigate = useNavigate();
    const locator = useLocation();
    let { registerId } = useParams();

    const [headerTitle, setHeaderTitle] = useState('Editar entrada');
    const [title, setTitle] = useState('');
    const [userName, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [url, setUrl] = useState('');
    const [notes, setNotes] = useState('');
    const [parentId, setParentId] = useState(0);
    const [loading, setLoading] = React.useState(true);
    const [saveError, setSaveError] = useState("");
    const [group, setGroup] = useState(false);
    const [modalSettings, setModalSettings] = useState({});
    const visibilityIconHtml = "<img id='visibilityIcon' src='"+visibilityIcon+"' />";

    let emptyAttachs: string[] = [];
    const [attachments, setAttachments] = useState(emptyAttachs);

    const RegisterEditContext = React.createContext({
        title,
        userName,
        password,
        passwordConfirm,
        passwordError,
        url,
        notes,
    });

    const getDataFromApi = async() => {
        sourceTracking("getDataFromApi", "RegisterEdit");

        // let registersList = await api.get("/register/"+registerId);
        //let item = registersList.items[0];
        try{
            let item = await api.get("/register/"+registerId);

            setTitle(item.title);
            setUsername(item.username);
            setPassword(item.password);
            setPasswordConfirm(item.password);
            setUrl(item.url);
            setNotes(item.notes);
            setLoading(false);
            setParentId(parseInt(item.parent_id));
            setAttachments(item.attach);
        } catch (e: any){
            if (e.response.status === 403){
                LocalStorage.remove("authToken");
                NavigateLib.navigate(navigate, "/login");
            } else {
                setSaveError("Erro ao salvar dados");
            }
        }
    };

    const handleFileChoose = async(fileObj: any, filename: string) => {
        sourceTracking("handleFileChoose", "RegisterEdit");

        const formData = new FormData();
        formData.append('file', fileObj);
        formData.append('alias', filename)
        formData.append('register_id', registerId+"");

        try{
            // TODO: Mudar o formato das chamadas de API para um objeto { url, data, headers } para ficar mais legível
            await api.post('/attach', formData,
            {
                "Content-Type": "multipart/form-data"
            });

            let titleFromHtml = (document.getElementById('titleInput') as HTMLInputElement) ? (document.getElementById('titleInput') as HTMLInputElement).value : null;
            let usernameFromHtml = (document.getElementById('usernameInput') as HTMLInputElement) ? (document.getElementById('usernameInput') as HTMLInputElement).value : null;
            let passwordFromHtml = (document.getElementById('passwordInput') as HTMLInputElement) ? (document.getElementById('passwordInput') as HTMLInputElement).value : null;
            let passwordConfirmFromHtml = (document.getElementById('passwordConfirmInput') as HTMLInputElement) ? (document.getElementById('passwordConfirmInput') as HTMLInputElement).value : null;
            let urlFromHtml = (document.getElementById('urlInput') as HTMLInputElement) ? (document.getElementById('urlInput') as HTMLInputElement).value : null;
            let notesFromHtml = (document.getElementById('notesTextArea') as HTMLInputElement) ? (document.getElementById('notesTextArea') as HTMLInputElement).value : null;

            let item = await api.get("/register/"+registerId);
            setAttachments(item.attach);
            setTitle(titleFromHtml+"");
            setUsername(usernameFromHtml+"");
            setPassword(passwordFromHtml+"");
            setPasswordConfirm(passwordConfirmFromHtml+"");
            setUrl(urlFromHtml+"");
            setNotes(notesFromHtml+"");
            setAttachments(item.attach)
        } catch(e: any){
            setSaveError("Erro ao fazer upload de arquivo");
        }
    }

    useEffect(() => {
        sourceTracking("useEffect", "RegisterEdit");

        let groupFromParam = NavigateLib.query(locator, 'group');

        if (!registerId){
            if (!groupFromParam){
                setHeaderTitle("Nova entrada");
            } else {
                setGroup(true);
                setHeaderTitle("Novo grupo");
            }
        } else {
            getDataFromApi();

            if (groupFromParam){
                setHeaderTitle("Editar grupo");
                setGroup(true);
            }
        }
        
        let parent_id = parseInt(NavigateLib.query(locator, 'parent_id')+"");
        if (!isNaN(parent_id) && parent_id !== 0){
            setParentId(parent_id);
        }
        setLoading(false);
    }, []);

    const handleSubmit = async () => {
        sourceTracking("handleSubmit", "RegisterEdit");

        let titleFromHtml = (document.getElementById('titleInput') as HTMLInputElement) ? (document.getElementById('titleInput') as HTMLInputElement).value : null;
        let usernameFromHtml = (document.getElementById('usernameInput') as HTMLInputElement) ? (document.getElementById('usernameInput') as HTMLInputElement).value : null;
        let passwordFromHtml = (document.getElementById('passwordInput') as HTMLInputElement) ? (document.getElementById('passwordInput') as HTMLInputElement).value : null;
        let passwordConfirmFromHtml = (document.getElementById('passwordConfirmInput') as HTMLInputElement) ? (document.getElementById('passwordConfirmInput') as HTMLInputElement).value : null;
        let urlFromHtml = (document.getElementById('urlInput') as HTMLInputElement) ? (document.getElementById('urlInput') as HTMLInputElement).value : null;
        let notesFromHtml = (document.getElementById('notesTextArea') as HTMLInputElement) ? (document.getElementById('notesTextArea') as HTMLInputElement).value : null;
        //let attachmentsFromHtml = (document.getElementsByClassName('attachment') as HTMLCollectionOf<Element>) ? (document.getElementsByClassName('attachment') as HTMLCollectionOf<Element>) : null;

        setSaveError("");
        setLoading(true);

        if (passwordFromHtml !== passwordConfirmFromHtml){
            setPasswordError(true);
            setLoading(false);

            setTitle(titleFromHtml+"");
            setUsername(usernameFromHtml+"");
            setPassword(passwordFromHtml+"");
            setPasswordConfirm(passwordConfirmFromHtml+"");
            setUrl(urlFromHtml+"");
            setNotes(notesFromHtml+"");

            return;
        } else {
            setPasswordError(false);
        }

        try{
            if (registerId){
                await api.put("/register/"+registerId, {
                    "title": titleFromHtml,
                    "username": usernameFromHtml,
                    "password": passwordFromHtml,
                    "icon": null,
                    "url": urlFromHtml,
                    "notes": notesFromHtml,
                    "parent_id": parentId
                });

                if (group === false){
                    setTimeout(async () => {
                        await NavigateLib.navigate(navigate, '/registerDetails/'+registerId);
                    }, 1000);
                } else {
                    setTimeout(async () => {
                        await NavigateLib.navigate(navigate, '/registerList/'+registerId);
                    }, 1000);
                }
            } else {
                await api.post("/register", {
                    "title": titleFromHtml,
                    "username": usernameFromHtml,
                    "password": passwordFromHtml,
                    "icon": null,
                    "url": urlFromHtml,
                    "notes": notesFromHtml,
                    "parent_id": parentId === 0 ? null : parentId,
                    "group": group ? group : null
                });

                setTimeout(async () => {
                    if (parentId !== 0){
                        await NavigateLib.navigate(navigate, '/registerList/'+parentId);
                    } else {
                        await NavigateLib.navigate(navigate, '/');
                    }
                }, 1000);
            }
        } catch(e: any){
            if (e.response.status === 403){
                // TODO: Exibir a tela de login e depois continuar a operação, evitando perder os dados já editados
                LocalStorage.remove("authToken");
                await NavigateLib.navigate(navigate, '/login');
            }
            setSaveError("Erro ao salvar dados");
            setLoading(false);
        }

        //const db = await api.get("http://www.insiderti.com.br/memorygame/getCardsData?cardsAmount=5");
    }

    const removeAttach = async (id: string, alias: string) => {
            sourceTracking("removeAttach", "RegisterEdit");

            setModalSettings({
                open: true,
                title: 'Remover anexo',
                message: 'Deseja realmente remover o anexo '+alias+' ?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: async () => {
                            let titleFromHtml = (document.getElementById('titleInput') as HTMLInputElement) ? (document.getElementById('titleInput') as HTMLInputElement).value : null;
                            let usernameFromHtml = (document.getElementById('usernameInput') as HTMLInputElement) ? (document.getElementById('usernameInput') as HTMLInputElement).value : null;
                            let passwordFromHtml = (document.getElementById('passwordInput') as HTMLInputElement) ? (document.getElementById('passwordInput') as HTMLInputElement).value : null;
                            let passwordConfirmFromHtml = (document.getElementById('passwordConfirmInput') as HTMLInputElement) ? (document.getElementById('passwordConfirmInput') as HTMLInputElement).value : null;
                            let urlFromHtml = (document.getElementById('urlInput') as HTMLInputElement) ? (document.getElementById('urlInput') as HTMLInputElement).value : null;
                            let notesFromHtml = (document.getElementById('notesTextArea') as HTMLInputElement) ? (document.getElementById('notesTextArea') as HTMLInputElement).value : null;

                            try{
                                await api.delete("/attach/"+id);

                                let item = await api.get("/register/"+registerId);
                                setAttachments(item.attach);
                                setTitle(titleFromHtml+"");
                                setUsername(usernameFromHtml+"");
                                setPassword(passwordFromHtml+"");
                                setPasswordConfirm(passwordConfirmFromHtml+"");
                                setUrl(urlFromHtml+"");
                                setNotes(notesFromHtml+"");
                            } catch (e: any){
                                if (e.response.status === 403){
                                    LocalStorage.remove("authToken");
                                    NavigateLib.navigate(navigate, "/login");
                                } else {
                                    setSaveError("Erro ao apagar anexo");
                                }
                            }
                            setModalSettings({open: false});
                        }
                    },
                    {
                        label: 'Não',
                        onClick: async () => {
                            setModalSettings({open: false});
                        }
                    }
                ]
        })
    }

    const handleChangeTitle = (value: string) => {
        sourceTracking("handleChangeTitle", "RegisterEdit");
        //setTitle(value);
    }

    const handleChangeUsername = (value: string) => {
        sourceTracking("handleChangeUsername", "RegisterEdit");
        //setUsername(value);
    }

    const handleChangePassword = (value: string) => {
        sourceTracking("handleChangePassword", "RegisterEdit");

        //setPassword(value);
    }

    const handleChangePasswordConfirm = (value: string) => {
        sourceTracking("handleChangePasswordConfirm", "RegisterEdit");

        //setPasswordConfirm(value);
    }

    const handleChangeUrl = (value: string) => {
        sourceTracking("handleChangeUrl", "RegisterEdit");

        //setUrl(value)
    }

    const handleChangeNotes = (value: string) => {
        sourceTracking("handleChangeNotess", "RegisterEdit");

        //setNotes(value)
    }

    const passwordGenerate = () => {
        let password = generateRandomPassword();
        (document.getElementById('passwordInput') as HTMLInputElement).value = password;
        (document.getElementById('passwordConfirmInput') as HTMLInputElement).value = password;
        unsecuredCopyToClipboard(password, false);

        toast.success("Senha gerada e copiada para a área de transferência", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    }
    
    const togglePassVisibility = () => {
        sourceTracking("togglePassVisibility", "RegisterEdit");

        let passwordInputType = (document.getElementById('passwordInput') as HTMLInputElement).type;

        if (passwordInputType === "password"){
            (document.getElementById('passwordInput') as HTMLInputElement).type = 'text';
            (document.getElementById('passwordConfirmInput') as HTMLInputElement).type = 'text';
            (document.getElementById('togglePassVisibility') as HTMLInputElement).innerHTML = visibilityIconHtml+"&nbsp;&nbsp;&nbsp;&nbsp;Ocultar senha";
        } else {
            (document.getElementById('passwordInput') as HTMLInputElement).type = 'password';
            (document.getElementById('passwordConfirmInput') as HTMLInputElement).type = 'password';
            (document.getElementById('togglePassVisibility') as HTMLInputElement).innerHTML = visibilityIconHtml+"&nbsp;&nbsp;&nbsp;&nbsp;Exibir senha";
        }
    }

    return (
        loading ? <><Loading /></> : <>

            {saveError !== "" ? <>
                    <br/>
                    <DivError>
                        {saveError}
                    </DivError>
            </>: <></>}

            <RegisterEditContext.Provider value={{
                title,
                userName,
                password,
                passwordConfirm,
                passwordError,
                url,
                notes
            }}>
                <ItemEditHeader title={headerTitle} save={handleSubmit} setLoading={setLoading} />
                <Container>
                    <br />
                    <StaticIcon src={titleIcon} />
                    <Input
                        id="titleInput"
                        placeholder="Título"
                        label="Título"
                        onChange={handleChangeTitle}
                        value={title}
                        locked={false}
                    />
                    {group ? <>
                    </>: <>
                        <br />
                        <StaticIcon src={userIcon} />
                        <Input
                            id="usernameInput"
                            placeholder="Nome do usuário"
                            label="Nome do usuário"
                            onChange={handleChangeUsername}
                            value={userName}
                            locked={false}
                        />
                        <br />
                        <StaticIcon src={keyIcon} />
                        <Button 
                            id="togglePassVisibility"
                            onClick={() => togglePassVisibility() }
                            html={visibilityIconHtml+"&nbsp;&nbsp;&nbsp;&nbsp;Exibir senha"}
                            style={{
                                opacity: 0.8,
                                position: 'relative',
                                left: '10px',
                                bottom: '5px',
                                padding: '1px',
                                border: '1px solid #ddd',
                                width: '160px',
                                height: '42px',
                                lineHeight: '28px',
                                color: '#000',
                                backgroundColor: '#fff'
                            }}
                        ></Button>
                        <Button 
                            id="generatePassword"
                            onClick={() => passwordGenerate() }
                            html={"<img id='generatePasswordIcon' src='"+passwordIcon+"' />&nbsp;&nbsp;&nbsp;&nbsp;Gerar senha"}
                            style={{
                                opacity: 0.8,
                                position: 'relative',
                                left: '20px',
                                bottom: '5px',
                                padding: '1px',
                                border: '1px solid #ddd',
                                width: '150px',
                                height: '42px',
                                lineHeight: '28px',
                                color: '#000',
                                backgroundColor: '#fff'
                            }}
                        ></Button>
                        <Input
                            id="passwordInput"
                            placeholder="Senha"
                            type="password"
                            label="Senha"
                            onChange={handleChangePassword}
                            value={password}
                            locked={false}
                        />
                        <br />
                        <Input
                            id="passwordConfirmInput"
                            placeholder="Confirmação de senha"
                            type="password"
                            label="Confirmação de senha"
                            onChange={handleChangePasswordConfirm}
                            value={passwordConfirm}
                            locked={false}
                        />
                        <br />
                        {passwordError ? <DivError>As senhas não conferem</DivError> : <></>}
                        <StaticIcon src={globeIcon} />
                        <Input
                            id="urlInput"
                            placeholder="URL"
                            label="URL"
                            onChange={handleChangeUrl}
                            value={url}
                            locked={false}
                        />
                        <br />
                        <StaticIcon src={notesIcon} />
                        <TextArea
                            id="notesTextArea"
                            placeholder="Notas"
                            label="Notas"
                            onChange={handleChangeNotes}
                            value={notes}
                            locked={false}
                        />

                        {(headerTitle !== "Nova entrada") ? <>
                            <br />
                            <StaticIcon src={attachmentIcon} />
                            <AttachmentsDiv>
                                {(attachments.length > 0) ? <>
                                    {attachments.map((item: any) => {
                                        return(
                                            <React.Fragment key={item.id}>
                                                <AttachmentItem  id={item.id} onClick={() => {removeAttach(item.id, item.alias)}}>
                                                    <AttachmentText>
                                                        {item.alias}
                                                    </AttachmentText>
                                                    &nbsp;&nbsp;
                                                    <img style={{position: 'absolute', right: '15px'}} src={deleteIcon} />
                                                </AttachmentItem>
                                            </React.Fragment>
                                        )
                                    })}
                                </> : <>
                                </>}
                            </AttachmentsDiv>
                            
                                    <Input
                                        id="fileInput"
                                        type="file"
                                        title="Adicionar anexo"
                                        onChange={handleFileChoose}
                                    />
                                </>:<></>
                            }
                        </>}
                </Container>
                <ModalDialog settings={modalSettings} />
            </RegisterEditContext.Provider>
        </>
    )
}

export { RegisterEdit }