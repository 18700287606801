import { LocalStoragetInterface } from '../../localStoragetInterface';
import { Log } from "@infra/log";

class ReactLocalStorage implements LocalStoragetInterface {
    public async remove(key: string): Promise<boolean> {
        try {
            localStorage.setItem(key, "");
            localStorage.setItem(key + "_expiresIn", "");
        } catch (e) {
            Log.error(
                "remove: Error removing key [" +
                key +
                "] from localStorage: " +
                JSON.stringify(e)
            );
            return false;
        }
        return true;
    }
    
    public async get(key: string): Promise<string|number|null> {
        const now = Date.now();

        let value = localStorage.getItem(key + "key");
        let expiresIn = parseInt(localStorage.getItem(key + "_expiresIn")+"");
    
        if (expiresIn === undefined || expiresIn === null) {
            expiresIn = 0;
        }
    
        if (expiresIn === 0 && value === null) {
            return null;
        }
    
        expiresIn = Math.abs(expiresIn);
        if (expiresIn < now) {
            let localStorageObj = new ReactLocalStorage();
            await localStorageObj.remove(key);
            return null;
        }
        else {
            try {
                const value = localStorage.getItem(key);
                return value;
            } catch (e) {
                Log.error(
                "getStorage: Error reading key [" +
                    key +
                    "] from localStorage: " +
                    JSON.stringify(e)
                );
                return null;
            }
        }
    }

    public async set(key: string|number, value: string|number, expires?: number): Promise<boolean> {
        if (expires === undefined || expires === null) {
            expires = 24 * 60 * 60;
        }
    
        const now = Date.now();
        const schedule = now + expires * 1000;
        try {
            localStorage.setItem(key+"", value+"");
            localStorage.setItem(key + "_expiresIn", schedule+"");
        } catch (e) {
            Log.error(
                "setStorage: Error setting key [" +
                key +
                "] in localStorage: " +
                JSON.stringify(e)
            );
            return false;
        }
    
        return true;
    }
}
    
export default ReactLocalStorage;