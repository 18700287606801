import { HomeHeaderContainer, HomeHeaderTitle, HomeHeaderToolImg  } from './style';
import findIcon from '@src/assets/icons/find.png';
import backIcon from '@src/assets/icons/back.png';
import menuMoreHeaderIcon from '@src/assets/icons/menuMoreHeader.png';
import { NavigateLib } from '@src/lib/navigate';
import { useNavigate, useParams } from 'react-router-dom';
import saveHeaderIcon from '@src/assets/icons/saveHeader.png';
import sourceTracking from "@src/lib/sourceTracking";

function ItemEditHeader(props: any){
    const navigate = useNavigate();
    let { registerId } = useParams();

    const backHistory = () => {
        sourceTracking("backHistory", "ItemEditHeader");
        props.setLoading(true);
        NavigateLib.backHistory(navigate, -1);
    }

    const saveItem = () => {
        sourceTracking("saveItem", "ItemEditHeader");
        props.setLoading(true);
        props.save();
    }

    return (
        <HomeHeaderContainer>
            <div onClick={backHistory}>
                <HomeHeaderToolImg src={backIcon} />
            </div>
            <HomeHeaderTitle>
                {props.title}
            </HomeHeaderTitle>

            <div  onClick={saveItem}>
                <HomeHeaderToolImg style={{padding: '12px'}}  src={saveHeaderIcon} />
            </div>
        </HomeHeaderContainer>
    )
};

export { ItemEditHeader };