import { ContainerStyledCheckbox, StyledCheckbox, StyledCheckboxTitle } from './style';

function Checkbox (props: any){
    const defaultChecked: object = {defaultChecked: props.checked};

    const checkboxToggle = () => {
        let checkbox = (document.getElementById(props.id) as HTMLInputElement);
        checkbox.checked = !checkbox.checked;
    }

    return (
        <ContainerStyledCheckbox onClick={checkboxToggle}>
            <StyledCheckbox type="checkbox" id={props.id} {...defaultChecked} /> <StyledCheckboxTitle>{props.title}</StyledCheckboxTitle>
        </ContainerStyledCheckbox>
    )
}

export { Checkbox };