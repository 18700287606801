import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const TitleItemList = styled.div`
    flex: 1;
`;

const TypeItemList = styled.div`
    margin-right: 3px;
`;

const TextAreaItemList = styled.div`
    white-space: pre-line;
    margin-right: 3px;
`

const IconContainer = styled.div`
    margin-right: 10px;
`;

const DataContainer = styled.div`
    width: 74%;
    word-wrap: break-word;
`;

const SubDataContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2px;
    color: #777;
    font-size: 0.8em;
`;

const TitleItemDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2px;
    color: #777;
    font-size: 0.8em;
`;

const SubDataDetailsContainer = styled.div`
    flex: 1;
    margin-top: 0.4em;
`;


const IconItemList = styled.img`
    flex: 1;
    padding: 12px;
    max-width: 32px;
    margin-top: 8px;
`;

const ActionsIcon = styled.img`
    flex: 1;
    padding: 12px;
    max-width: 24px;
    max-height: 24px;
`;

const DivError = styled.div`
    padding: 10px;
    color:  #fff;
    background: rgba(255,37,37,0.85);
    font-weight: bold;
    margin-bottom: 15px;
`;

const AttachmentsDiv = styled.div`
    flex: 1;
    margin-left: 55px;
`;

const AttachmentItem = styled.div`
    padding-top: 10px;
    padding-bottom: 25px;
    border: 0px solid #000;
    padding-left: 10px;
    align-items: center;
    display: flex;
`;

const AttachmentText = styled.div`
    position: relative;
    top: 3px;
    color: #000;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    width: 100%;
    height: 25px;
`;

export { Container, TitleItemList, TypeItemList, IconItemList, ActionsIcon, IconContainer, DataContainer, SubDataContainer, TitleItemDetails, SubDataDetailsContainer, DivError, TextAreaItemList, AttachmentsDiv, AttachmentItem, AttachmentText };