import styled from 'styled-components';

const Container = styled.div`
    padding: 12px;
`

const StaticIcon = styled.img`
    flex: 1;
    max-width: 32px;
    max-height: 32px;
`;

const DivError = styled.div`
    padding: 10px;
    color:  #fff;
    background: rgba(255,37,37,0.85);
    font-weight: bold;
    margin-bottom: 15px;
`;

export { Container, StaticIcon, DivError };
