import styled from 'styled-components';


const LegendContainer = styled.div`
    display: flex;
    position: relative;
    left: -50px;
`;

const LegendValue = styled.span`
    font-family: Monospace;
    position: relative;
    top: 2px;
    right: 12px;
    font-size: 1.2em;
    font-weight: bold;
`;

const UlLegend = styled.ul`

`;

const LiLegend = styled.li<{ color: string }>`
    list-style-type: none;
    line-height: 25px;
    font-family: Monospace;
    position: relative;
    padding-bottom: 30px;

    &:before {
        content: "·";
        font-size: 200px;
        vertical-align: middle;
        color: ${({ color = "#000" }) => color};
    }
`;

const SubLegend = styled.span`
    position: relative;
    bottom: -5px;
    left: 49px;
    color: #999;
    font-size: 1.1em;
    font-weight: bold;
`

export { LegendContainer, SubLegend, LiLegend, LegendValue, UlLegend };