import styled from 'styled-components';

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
`

const Gif = styled.img`
    flex: 1;
    padding-top: 120px;
    max-width: 128px;
`;


export { LoadingContainer, Gif };