import { LocalStorage } from '../localStorage';

class Auth {
    public async getLocalAuthToken(): Promise<string> {
        let token = await LocalStorage.get('authToken');

        return token+"";
    }
}

export default new Auth();