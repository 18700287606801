import "./style.css";
import React, {useState, useEffect, ChangeEvent} from 'react';
import { Button } from '../Button';
import uploadIcon from '@src/assets/icons/upload.png';
import { v4 as uuidv4 } from 'uuid';

function Input(props: any) {
    const { locked, id } = props;

    const defaultAutocomplete: string = props.autocomplete ? props.autocomplete : "";

    const [inputId, setInputId] = useState(id);
    const [active, setActive] = useState(false);
    const [inputClassName, setInputClassName] = useState("");
    const [value, setValue] = useState("");
    const [type, setType] = useState("text");
    const [error, setError] = useState("");
    const [label, setLabel] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [title, setTitle] = useState("");
    const [style, setStyle] = useState({});
    const [divClassName, setDivClassName] = useState(`input ${(value) && "active"} ${locked && "locked"}`)

    const clickInFileInput = () => {
        let inputFromHtml = document.getElementById(id);
        inputFromHtml!.click();
    }

    useEffect(() => {
        if (props.value){
            setValue(props.value);
        }
        if (props.placeholder){
            setPlaceholder(props.placeholder);
        }
        if (props.label){
            setLabel(props.label);
        }

        if (props.type){
            setType(props.type);
        }

        if (props.title){
            setTitle(props.title);
        }

        if (props.className){
            setInputClassName(props.className)
        }

        if (props.type === "file"){
            setDivClassName("");
        }

        if (props.style){
            setStyle(props.style);
        }

        if (props.autoFocus){
            if (!inputId){
                setInputId(uuidv4());
            }
        }
    }, []);

    useEffect(() => {
        if (props.autoFocus){
            let input = document.getElementById(inputId);
            input?.click();
            input?.focus();
        }
    }, [inputId])

    const changeValue = (event: any) => {
        const valueFromEvent = event.target.value;
        setValue(valueFromEvent);
        setError("");

        if (props.onChange){
            if (type !== "file"){
                props.onChange(valueFromEvent);
            } else {
                let alias = event.target.files[0].name;
                alias = alias.replace(/C:\\fakepath\\/, '');
                props.onChange(event.target.files[0], alias);
            }
        }
    }

    const handleKeyPress = (event: any) => {
        if (props.onKeyPress){
            props.onKeyPress(event);
        }
    }

    const handleStatusToggle = (status: boolean) => {
        setActive(status);
    }

    const handleKeyUp = (event: any) => {
        if (props.onKeyUp){
            return props.onKeyUp(event);
        }
    }

    const handleKeyDown = (event: any) => {
        if (props.onKeyDown){
            return props.onKeyDown(event);
        }
    }

    const handleOnFocus = (event: any) => {
        if (!(!locked && handleStatusToggle(false))){
            return false;
        }

        if (props.onFocus){
            return props.onFocus(event);
        }
    }
    const handleOnBlur = (event: any) => {
        if (!(!locked && handleStatusToggle(false))){
            return false;
        }

        if (props.onBlur){
            return props.onBlur(event);
        }
    }

    return (
        <div className={divClassName}>
            {active &&
            value
            }
                <input
                    id={inputId}
                    className={inputClassName}
                    type={type}
                    value={value}
                    style={style}
                    autoComplete={defaultAutocomplete}
                    placeholder={placeholder}
                    onChange={changeValue}
                    onKeyPress={handleKeyPress}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                />
                {type === "file" ? <>
                    {/*
                    <Button
                        style={{
                            marginBottom: '60px',
                            position: 'relative',
                            left: '-130px',
                            padding: '0',
                            border: '1px solid #ddd',
                            width: '75px',
                            height: '75px',
                            borderRadius: '100%',
                            color: 'none',
                            backgroundColor: '#fff',
                            "-webkit-box-shadow": " 3px 3px 3px 0px rgba(0,0,0,0.25)"
                        }}
                        html={"<img src='"+uploadIcon+"' />"}
                        onClick={() => clickInFileInput()}
                    ></Button>
                    */}
                     <Button onClick={() => clickInFileInput()} title={title}>
                    </Button>
                </> : <></>}
                <label className={error && "error"}>
                    {error || label}
                </label>
        </div>
    )
}
/*
-webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
*/
export { Input }