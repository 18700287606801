import { useNavigate, useLocation } from 'react-router-dom';

function RouteManager({ children }: any){
    const navigate = useNavigate();
    //const location = useLocation();   

    window.addEventListener('popstate', function (event) {
        navigate(0);
    });
    //&& location.pathname !== "/login"
    return (
        <></>
    )
}

export { RouteManager };