import { Env } from "@infra/env";

class NativeLog {
    // LogLevels
    // 0 = Errors / 1 = Warning / 2 = Info / 3 = Debugs

    public static async debug(...content: any){
        content.map(async function (item: any){
            const logLevel = await Env.get("LOG_LEVEL");
            
            if(parseInt(logLevel) === 3){  
                const logConsole = await Env.get("LOG_CONSOLE");
                if (logConsole === "true"){
                    console.debug(item);
                }
            }
        })
    }

    public static async info(...content: any){
        content.map(async function (item: any){
            const logLevel = await Env.get("LOG_LEVEL");

            if(parseInt(logLevel) >= 2){
                const logConsole = await Env.get("LOG_CONSOLE");

                if (logConsole === "true"){
                    console.debug(item);
                }
            }
        })
    }

    public static async warn(...content: any){
        content.map(async function (item: any){
            const logLevel = await Env.get("LOG_LEVEL");

            if(parseInt(logLevel) >= 1){
                const logConsole = await Env.get("LOG_CONSOLE");

                if (logConsole === "true"){
                    console.warn(item);
                }
            }
        });
    }

    public static async error(...content: any){
        content.map(async function (item: any){
            const logLevel = await Env.get("LOG_LEVEL");
            
            if(parseInt(logLevel) >= 0){
                const logConsole = await Env.get("LOG_CONSOLE");

                if (logConsole === "true"){
                    console.error(item);
                }
            }
        });
    }
}

export default NativeLog;