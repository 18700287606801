import { StyleItemList, TitleItemList, TypeItemList, IconItemList, NextIcon, IconContainer, DataContainer, SubDataContainer } from './style';
import defaultFolderIcon from '@src/assets/icons/defaultFolder.png';
import defaultItemIcon from '@src/assets/icons/defaultItem.png';
import nextIcon from '@src/assets/icons/next.png';
import emptyIcon from '@src/assets/icons/empty.png';

function ItemList(props: any){
    // TODO: Default icon sempre aparecendo. Tornar dinâmico
    // type: "root" | "child"

    return (
        <StyleItemList onClick={props.onClick}>
            <IconContainer>
                {
                    props.childs.length > 0 ? 
                    <IconItemList src={defaultFolderIcon} /> :
                    <IconItemList src={defaultItemIcon} />
                }
            </IconContainer>
            <DataContainer>
                <TitleItemList>{props.title}</TitleItemList>
                <SubDataContainer>
                    <TypeItemList>
                        {props.childs.length > 0 ? "Grupo": "Entrada" }
                    </TypeItemList>
                </SubDataContainer>
            </DataContainer>
            <NextIcon src={nextIcon} />
        </StyleItemList>
    )
}

export { ItemList }