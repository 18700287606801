import { useState } from "react";
import cn from "classnames";
import { MdAdd } from "react-icons/md";
import './styles.css';

const FloatingButton = (props: any) => {
  const [open, setOpen] = useState(false);

  const mouseEnter = () => setOpen(true);

  const mouseLeave = () => setOpen(false);

  const mainIcon = props.settings.mainIcon;
  const actions = props.settings.actions;

  return (
    <>
      {actions.length === 1 ? <>
        <ul
            className={cn("fab-container", { open })}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          >
            <li className="fab-button" onClick={actions[0].onClick}>
              {actions[0].icon}
            </li>
        </ul>
      </> : <>
        <ul
            className={cn("fab-container", { open })}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          >
            <li className="fab-button">
              { mainIcon }
            </li>
            {actions.map((action: any, index: number) => (
              <li
                style={{ transitionDelay: `${index * 25}ms` }}
                className={cn("fab-action", { open })}
                key={action.label}
                onClick={action.onClick}
              >
                {action.icon}
                <span className="tooltip">{action.label}</span>
              </li>
            ))}
        </ul>
      </>  
      }
    </>
  )
};

export default FloatingButton;