import styled from 'styled-components';

const DivError = styled.div`
    padding: 10px;
    color:  #fff;
    background: rgba(255,37,37,0.85);
    font-weight: bold;
    margin-bottom: 15px;
`;

const DivSearchStatus = styled.div`
padding: 10px;
color:  #fff;
background: rgba(88,33,125,0.45);
font-weight: bold;
margin-bottom: 15px;
`;

export { DivError, DivSearchStatus }