import styled from 'styled-components';

const StatsHeaderContainer = styled.div`
    display: -webkit-box;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding-left: 10px;
    background-color: #56009e;
    color: #fff;
    font-weight: bold;
    z-index: 999;
`

const StatsHeaderTitle = styled.div``;

const StatsHeaderToolImg = styled.img`
    flex: 1;
    max-width: 32px;
    max-height: 32px;
`;

export { StatsHeaderContainer, StatsHeaderToolImg, StatsHeaderTitle };